<template lang="html">
  <div v-if="image" class="Download">
    <picture v-if="!videos">
      <source
        :srcset="'/src/' + image.image.split('-')[0] + '-800.webp'"
        media="(min-width: 400px)"
      />
      <source
        :srcset="'/src/' + image.image.split('-')[0] + '-800.jpg'"
        media="(min-width: 400px)"
      />
      <img
        :src="'/src/' + image.image"
        alt="Preview Image"
        class="ArtworksPreviewImage"
        @load="show = true"
      />
    </picture>
    <video
      v-else
      controls
      class="videoDownload"
      :poster="'/src/' + image.image.split('-')[0] + '-800.webp'"
    >
      <source
        :src="
          '/src/' +
            videos.filter(
              video => JSON.parse(video.metadata).fileformat == 'mp4',
            )[0].file
        "
        type="video/mp4"
      />
    </video>

    <a
      :href="'/downloads/?image=' + item.item_id"
      target="top"
      title="Click to download Image"
      v-if="!videos"
    >
      <div class="Arrow">
        <img src="@/assets/img/icons/ArrowDown.png" />
      </div>
    </a>

    <div v-else class="videos">
      <span class="Arrow">
        <img src="@/assets/img/icons/ArrowDown.png" />
      </span>

      <div class="options">
        <div v-for="video in videos">
          <a
            :href="'/downloads/?video=' + video.id"
            target="top"
            :title="'Click to download Video'"
          >
            <span>{{ video.file.split('.')[1] }}</span>

            {{ JSON.parse(video.metadata).video.resolution_x }}x{{
              JSON.parse(video.metadata).video.resolution_y
            }}
            ({{ bytesToSize(JSON.parse(video.metadata).filesize) }})
          </a>
        </div>
      </div>
    </div>

    <Credit :content="image" />
  </div>
</template>

<script>
import Credit from '@/components/Container/Caption.vue';
import axios from 'axios';
import qs from 'qs';

export default {
  components: {
    Credit,
  },
  props: {
    item: null,
  },
  data() {
    return {
      image: null,
      videos: null,
    };
  },
  created() {
    this.fetchFile();
  },
  methods: {
    fetchFile() {
      axios
        .post('/api/images/', qs.stringify({ id: this.item.item_id }))
        .then(res => {
          this.image = res.data.content.filter(
            item => item.mimetype.includes('image') == true,
          )[0];

          const videos = res.data.content.filter(
            item => item.mimetype.includes('video') == true,
          );

          if (videos.length > 0) this.fetchVideos();
        })
        .catch(err => {
          console.log('Preview Item: Error', err);
        });
    },
    bytesToSize(bytes) {
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    fetchVideos() {
      axios
        .post(
          '/api/downloads_videos/',
          qs.stringify({ q: { item_id: this.item.item_id } }),
        )
        .then(res => {
          this.videos = res.data.content;
        })
        .catch(err => {
          console.log('Preview Item: Error', err);
        });
    },
  },
};
</script>

<style lang="css" scoped>
.Download {
  position: relative;
  margin-bottom: 24px;
  overflow: hidden;
}

.Download img {
  display: block;
  width: 100%;
  margin-bottom: 12px;
}

.Arrow {
  position: absolute;
  top: -4px;
  left: -6px;
  width: 60px;
  display: inline-block;
  transition: all 1s;
}

.Arrow:hover img {
  filter: invert(1);
}

.videos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.videos .options {
  position: relative;
  top: 12px;
  left: 40px;
  width: 100%;
}

.videos .options div {
  display: inline-block;
  white-space: nowrap;
  background: white;
  font-size: 0.7rem;
  line-height: 28px;
  margin-right: 2px;
  padding: 0 5px;
  user-select: 0;
}

.videos .options span {
  font-weight: bold;
  text-transform: uppercase;
}

.videoDownload {
  display: inline;
  width: 100%;
}

figcaption {
  margin-top: 6px;
}

@media only screen and (min-width: 600px) {
  .Download {
    margin-bottom: 36px;
  }

  .videos .options div {
    padding: 0 8px;
    font-size: 0.8rem;
  }
}
</style>
