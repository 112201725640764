<template lang="html">
  <div class="SidebarDetails">
    <Title spacer="" />

    <Content :content="this.pageData.content" />

    <ul class="Documents">
      <li v-for="file in pageData._files" :key="file.id">
        <a
          :href="'/downloads/?pr=' + file.id"
          target="top"
          title="Click to download PDF"
        >
          {{ file.title }}
        </a>
      </li>
      <li>
        <a
          :href="'/downloads/?archive=' + pageData.id"
          target="top"
          title="Click to download Zip"
        >
          Download all images (ZIP)
        </a>
      </li>
    </ul>

    <div class="Downloads">
      <Download v-for="item in downloads" :item="item" :key="item.id" />
    </div>

    <Terms />

    <div class="Empty"></div>
  </div>
</template>

<script>
import stylesSidebar from "../assets/css/sidebar.css";
import Title from "@/components/Elements/Title.vue";
import Content from "@/components/Elements/Content.vue";
import Terms from "@/components/Elements/Terms.vue";
import Download from "@/components/Elements/Download.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Title,
    Content,
    Terms,
    Download,
  },
  computed: {
    ...mapGetters(["pageData", "pageList"]),
    downloads() {
      const downloads = this.pageData._downloads;
      return downloads.sort((a, b) => b.is_order - a.is_order);
    },
  },
  methods: {
    ...mapActions(["fetchItems"]),
  },
  created() {},
};
</script>

<style scoped>
.Downloads {
  position: relative;
  margin-top: 24px;
}

.Documents li {
  position: relative;
  padding: 12px 0;
  border-bottom: 1px solid black;
  font-weight: bold;
}

.Documents li:first-child {
  border-top: 1px solid black;
}

.Documents li span {
  cursor: pointer;
}

.Terms {
  border-top: 1px solid black;
  padding-top: 12px;
}

.Empty {
  display: none;
}

@media only screen and (min-width: 600px) {
  .SidebarDetails {
    display: block;
  }

  .Title {
    border: 0;
  }

  .Content {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .SidebarDetails {
    display: grid;
    grid-template-columns: 4fr 8fr;
    grid-column-gap: 24px;
    grid-template-areas:
      "Title Title"
      "Documents Content"
      "Terms Content"
      "Terms Downloads";
  }

  .Title {
    grid-area: Title;
  }

  .Terms {
    border-top: 0;
    padding-top: 36px;
    grid-area: Terms;
    padding-right: 24px;
    max-width: 360px;
  }

  .Documents {
    grid-area: Documents;
    padding-right: 24px;
    max-width: 360px;
  }

  .Content {
    grid-area: Content;
  }

  .Downloads {
    grid-area: Downloads;
  }

  .Empty {
    grid-area: Empty;
  }
}

@media only screen and (min-width: 900px) {
  .SidebarDetails {
    display: grid;
    grid-template-columns: 4fr 6fr 2fr;
    grid-column-gap: 24px;
    grid-template-areas:
      "Title Title Title"
      "Documents Content Empty"
      "Terms Content Empty"
      "Terms Downloads Empty";
  }
}
</style>
