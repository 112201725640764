<template lang="html">
  <figcaption class="Credits">
    <div v-if="content.artist != 'None'" class="CreditsArtist small">
      {{ content.artist }}
    </div>
    <div class="CreditsTitle smallTitle">
      {{ content.title }}
    </div>
    <div v-if="content.category == 'Artwork'" class="CreditsCreation small">
      <span
        v-if="
          content.technique == 'Collage' ||
            content.technique == 'Drawing' ||
            content.technique == 'Edition' ||
            content.technique == 'Photo collage' ||
            content.technique == 'Photomontage' ||
            content.technique == 'Scale model'
        "
        >{{ content.technique }}</span
      >

      <!-- FORMAT DATE -->
      {{ creation }}
      <span v-if="content.is_twoparts == 1">in two parts</span>
    </div>

    <!-- LOCATION -->
    <div
      v-if="content.category != 'Artwork' && content.location_hidden == 0"
      class="CreditsLocation small"
    >
      <span v-if="content.location">{{ content.location }}</span>
      <span v-if="content.location && content.year">, </span>
      <span v-if="content.year">{{ creation }} </span>
    </div>

    <!-- MATERIALS & DIMENSIONS -->
    <div
      v-if="content.category == 'Video' && content.description"
      class="CreditDetails small"
    >
      {{ content.description }}
    </div>

    <!-- MATERIALS & DIMENSIONS -->
    <div
      v-if="
        content.category == 'Artwork' &&
          (content.materials || content.dimension)
      "
      class="CreditDetails small"
    >
      <!-- MATERIALS -->
      <div v-if="content.materials">
        {{ content.materials }}
      </div>
      <!-- DIMENSION -->
      <div v-if="content.dimension">
        {{ content.dimension }}
      </div>
    </div>

    <!-- CREDITS -->
    <div class="CreditsCredits smaller">
      <div class="Spacer">
        &mdash;
      </div>

      <!-- COLLECTION -->
      <div v-if="content.collection" class="CreditsCollection">
        <span v-if="content.collection">{{ content.collection }}</span
        ><span v-if="content.collection_details"
          >. {{ content.collection_details }}</span
        >
      </div>
      <div v-if="content.artwork_status">
        <span v-if="content.artwork_status == 1">Whereabouts unknown</span>
        <span v-if="content.artwork_status == 2">No longer in existence</span>
      </div>

      <div>
        <span
          v-if="content.photographer && content.photographer != 'None'"
          class="CreditsPhotographer"
          >Photo: {{ content.photographer }}</span
        >
        <span v-if="content.copyright" class="CreditsCopyright">
          &copy; {{ content.year }} {{ content.copyright }}</span
        >
      </div>
    </div>
  </figcaption>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    content: null,
  },
  computed: {
    creation() {
      if (this.content) {
        switch (this.content.creation_id) {
          case 1:
            return dayjs(this.content.creation_to).year();
          case 2:
            return dayjs(this.content.creation_to).format("MMMM YYYY");
          case 3:
            return dayjs(this.content.creation_to).format("MMMM DD, YYYY");
          case 4:
            return (
              dayjs(this.content.creation_from).year() +
              "–" +
              dayjs(this.content.creation_to).year()
            );
          case 5:
            return "ca. " + dayjs(this.content.creation_to).year();
        }
      } else return false;
    },
  },
};
</script>

<style lang="css">
.Credits {
  width: calc(100% - 36px);
}

.smaller {
  font-size: 0.5rem;
  line-height: 0.9rem;
}

.small {
  font-size: 0.6rem;
  line-height: 1.2rem;
}

.smallTitle {
  font-size: 0.7rem;
  line-height: 1rem;
  font-weight: bold;
}

.CreditsArtist {
  color: #333;
  letter-spacing: 0.2px;
}

.CreditDetails {
  margin-top: 12px;
  line-height: 1rem;
}

.CreditsCredits {
  color: #666;
  letter-spacing: 0.2px;
}

.CreditsCollection,
.CreditsPhotographer {
  display: block;
}

@media only screen and (min-width: 600px) {
  .smaller {
    font-size: 0.6rem;
    line-height: 0.9rem;
  }

  .small {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }

  .smallTitle {
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: bold;
  }
}
</style>
