<template lang="html">
  <div class="Terms">
    <h3>Terms of Use</h3>
    <p>
      The images and video footage can only be used editorially. They can not be
      used for any type of advertising, commercial use or any other use of
      commercial nature. They can only be used in conjunction with media
      coverage about the artists and their work. They are protected by copyright
      worldwide. The names of both artists must be spelled correctly (just like
      this: Christo and Jeanne-Claude). The name of the photographer and the
      copyright notice must be printed next to the images. Reproductions of
      preparatory works of art must not be cropped or altered in any way.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.Terms {
  margin-bottom: 36px;
}
h3 {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 12px;
}

p {
  font-size: 0.8rem;
}
</style>
