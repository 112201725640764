var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('figcaption',{staticClass:"Credits"},[(_vm.content.artist != 'None')?_c('div',{staticClass:"CreditsArtist small"},[_vm._v(" "+_vm._s(_vm.content.artist)+" ")]):_vm._e(),_c('div',{staticClass:"CreditsTitle smallTitle"},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),(_vm.content.category == 'Artwork')?_c('div',{staticClass:"CreditsCreation small"},[(
        _vm.content.technique == 'Collage' ||
          _vm.content.technique == 'Drawing' ||
          _vm.content.technique == 'Edition' ||
          _vm.content.technique == 'Photo collage' ||
          _vm.content.technique == 'Photomontage' ||
          _vm.content.technique == 'Scale model'
      )?_c('span',[_vm._v(_vm._s(_vm.content.technique))]):_vm._e(),_vm._v(" "+_vm._s(_vm.creation)+" "),(_vm.content.is_twoparts == 1)?_c('span',[_vm._v("in two parts")]):_vm._e()]):_vm._e(),(_vm.content.category != 'Artwork' && _vm.content.location_hidden == 0)?_c('div',{staticClass:"CreditsLocation small"},[(_vm.content.location)?_c('span',[_vm._v(_vm._s(_vm.content.location))]):_vm._e(),(_vm.content.location && _vm.content.year)?_c('span',[_vm._v(", ")]):_vm._e(),(_vm.content.year)?_c('span',[_vm._v(_vm._s(_vm.creation)+" ")]):_vm._e()]):_vm._e(),(_vm.content.category == 'Video' && _vm.content.description)?_c('div',{staticClass:"CreditDetails small"},[_vm._v(" "+_vm._s(_vm.content.description)+" ")]):_vm._e(),(
      _vm.content.category == 'Artwork' &&
        (_vm.content.materials || _vm.content.dimension)
    )?_c('div',{staticClass:"CreditDetails small"},[(_vm.content.materials)?_c('div',[_vm._v(" "+_vm._s(_vm.content.materials)+" ")]):_vm._e(),(_vm.content.dimension)?_c('div',[_vm._v(" "+_vm._s(_vm.content.dimension)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"CreditsCredits smaller"},[_c('div',{staticClass:"Spacer"},[_vm._v(" — ")]),(_vm.content.collection)?_c('div',{staticClass:"CreditsCollection"},[(_vm.content.collection)?_c('span',[_vm._v(_vm._s(_vm.content.collection))]):_vm._e(),(_vm.content.collection_details)?_c('span',[_vm._v(". "+_vm._s(_vm.content.collection_details))]):_vm._e()]):_vm._e(),(_vm.content.artwork_status)?_c('div',[(_vm.content.artwork_status == 1)?_c('span',[_vm._v("Whereabouts unknown")]):_vm._e(),(_vm.content.artwork_status == 2)?_c('span',[_vm._v("No longer in existence")]):_vm._e()]):_vm._e(),_c('div',[(_vm.content.photographer && _vm.content.photographer != 'None')?_c('span',{staticClass:"CreditsPhotographer"},[_vm._v("Photo: "+_vm._s(_vm.content.photographer))]):_vm._e(),(_vm.content.copyright)?_c('span',{staticClass:"CreditsCopyright"},[_vm._v(" © "+_vm._s(_vm.content.year)+" "+_vm._s(_vm.content.copyright))]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }